<template>
  <!-- Start Containerbar -->
  <div id="containerbar">
    <!-- Start Rightbar -->
    <div class="rightbar">
      <!-- Start Breadcrumbbar -->
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-xs-12 col-md-12 col-lg-12 col-xl-7">
            <!--Se agrego el "col-xs-12"-->
            <h4 class="page-title">{{ $t("picking_movements.supplies") }}</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard"> {{ $t("picking_movements.home") }} </a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">{{ $t(title) }}</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="/salidas">{{ $t("picking_movements.movements") }}</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <!-- Start Contentbar -->
      <div class="contentbar">
        <div class="row justify-content-center">
          <!-- Start col -->
          <div class="col-md-12 col-lg-12 col-xl-12">
            <div class="card m-b-30">
              <div class="row">
                <div class="col-lg-12 col-xl-12">
                  <div class="card m-b-0">
                    <div class="card-header">
                      <div class="row align-items-center">
                        <div class="col-12">
                          <h5 align="left" class="card-title mb-0">
                            <i class="feather icon-truck mr-2"></i
                            >{{ $t("picking_movements.picking_movement") }}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <PickingTableComponent
                    ref="tableMovements"
                    :headers="pickingMovHeaders"
                    :isButton="true"
                    v-on:excelTable="excelTable"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- End col -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import { UtilFront } from "@/core";
import { ShipmentsRequest, OrderRequest, ExcelRequest } from "@/core/request";
import SelectBranchOffice from "@/common/select/SelectBranchOffice.vue";
import AlertMessageComponent from "@/common/message/AlertMessageComponent";
import PickingTableComponent from "@/components/picking/PickingTableComponent";
import { PICKING_MOV } from "@/core/DataTableHeadersLocale";

export default {
  data() {
    return {
      title: "picking.picking",

      /* Cabeceras */
      pickingMovHeaders: PICKING_MOV(),

      /* General */
      idUser: "",
      action: 1,
      loading: null,
      itemOrigin: "",
      //originId

      /* Listas de picking y validacion */
      pickingMovList: [],
    };
  },
  components: {
    SelectBranchOffice,
    AlertMessageComponent,
    PickingTableComponent,
  },
  computed: {
    ...mapState("auth", { branchOfficeState: "branchOffice" }),
  },
  methods: {
    // Funcion para el cambio del actionState
    ...mapMutations("auth", {
      updateAction: "UPDATE_ACTION",
    }),
    updateOrigin(data) {
      this.itemOrigin = data;

      //Mustro la pantalla de cargando
      this.loading = UtilFront.getLoadding();

      //Recupero la lista de los envios en estatus de picking y los picking validados
      ShipmentsRequest.shipmentsMovementHistory({
        idUser: this.idUser,
        idBranchOffice: data,
      })
        .then((response) => {
          let { success, message, data } = response.data.data;

          if (success) {
            this.$refs.tableMovements.reloadComponent(data);
          } else {
            //this.$refs.alertPicking.reloadComponent( "error", message );
          }
        })
        .catch((error) => {
          //console.error(error);
          //this.$refs.alertPicking.reloadComponent("internal","");
        })
        .finally(() => {
          //Elimino la pantalla de cargando
          this.loading.hide();
        });
    },
    excelTable() {
      this.$toast.success("Generando documento Xlsx");
      ExcelRequest.reportOrdersMovementHistory({
        idBranchOffice: this.itemOrigin,
      });
    },
  },
  created() {
    let infoUser = UtilFront.getDataUser();
    this.idUser = infoUser.idUser;
    /*  Asigan el valor del state para sucursal global */
    this.updateOrigin(this.branchOfficeState.idBranchoffice);
    this.updateAction(1);
  },
  watch: {
    branchOfficeState() {
      this.updateOrigin(this.branchOfficeState.idBranchoffice);
    },
    "$i18n.locale": function() {
      this.pickingMovHeaders = PICKING_MOV();
    },
  },
};
</script>

<!--Se agrego la parte de "style" para dar el formato-->
<style>
/*Código para modificar los botones */
@media (max-width: 576px) {
  .controlSize {
    text-align: center;
    float: right;
  }
  /*Código para modificar las barras de fecha o botones de busqueda*/
  .selectSize {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (min-width: 992px) {
  .controlSize {
    float: right;
  }
}
</style>
